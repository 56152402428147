import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "react-notifications-component/dist/theme.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./components/navigation/ProtectedRoute";

import { useTranslation } from "react-i18next";
import ReactNotification from "react-notifications-component";

// Features.
import ParentApp from "./features/app/ParentApp";
import Account from "./features/account/Account";
import Callbacks from "features/callbacks/Callbacks";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import TrackingAPI from "services/tracking";
import { Elements } from "@stripe/react-stripe-js";
import { StripeProvider } from "hooks/billing/StripeContext";
import { loadStripe } from "@stripe/stripe-js";
import { AuthProvider } from "@neurosolutionsgroup/authentication";

function Component() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(
      i18n.language.includes("fr") && i18n.language !== "fr" ? "fr" : "en"
    );
    document.title = t("title");

    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      TrackingAPI.initializeTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var stripePromise = null;

  if (process.env.REACT_APP_STRIPE_PUBLICKEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY);
  }

  return (
    <>
      <AuthProvider
        reCaptchaKey="6LcEgdIZAAAAANRwraZQ9OJHMbXchth7wIFPDRmp"
        i18n={i18n}
      >
        <Elements stripe={stripePromise}>
          <StripeProvider>
            <ReactNotification />
            <Router>
              <Switch>
                <Route path="/account" component={Account} />
                <ProtectedRoute path="/app" component={ParentApp} />
                <Route path="/callbacks" component={Callbacks} />
                <Redirect to="/account" />
              </Switch>
            </Router>
          </StripeProvider>
        </Elements>
      </AuthProvider>
    </>
  );
}

export default function App() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
