import CleverTap from "./CleverTap";
import FB from "./FB";
import GA from "./GA";
import Mixpanel from "./Mixpanel";

export enum AccountType {
  Email = "Email",
  Facebook = "Facebook",
  Apple = "Apple",
}

export enum AppStore {
  Google = "Google",
  Apple = "Apple",
}

export interface AccountDetails {
  email: string;
  firstName: string;
  lastName: string;
  city: string;
}

const getCurrentDate = (): string => {
  return new Date().toISOString();
};

const initializeTracking = async () => {
  Mixpanel.reset();
  Mixpanel.track("Web App Opened");
};

const userLoggedIn = async (
  playFabId: string,
  type: AccountType,
  email: string
) => {
  Mixpanel.alias(playFabId, email);

  Mixpanel.identify(playFabId);

  CleverTap.onUserLogin(playFabId, email);

  Mixpanel.people.set_once({
    "PlayFab ID": playFabId,
    "First Login On": getCurrentDate(),
    $email: email,
  });

  Mixpanel.track("Web App Login", {
    type: type,
  });

  CleverTap.trackEvent("Web App Login", {
    type: type,
  });

  CleverTap.profilePush({
    "PlayFab ID": playFabId,
    Email: email,
  });
};

const userRegistered = async (
  playFabId: string,
  type: AccountType,
  language: string,
  email: string
) => {
  Mixpanel.identify(email);

  Mixpanel.alias(playFabId, email);

  CleverTap.onUserLogin(playFabId, email);

  try {
    FB.trackRegistration();
  } catch (error) {
    console.log("Facebook tracking error.");
  }

  try {
    GA.trackRegistration();
  } catch (error) {
    console.log("Google tracking error.");
  }

  Mixpanel.track("Mixpanel Account Created", {
    "Email Captured By": "Web App",
  });

  //  Profile properties.
  Mixpanel.people.set({
    $email: email,
    "Account Type": type,
    "PlayFab ID": playFabId,
    Language: language,
  });

  CleverTap.profilePush({
    Email: email,
    "Account Type": type,
    "PlayFab ID": playFabId,
    Language: language,
  });

  const date = getCurrentDate();

  //  Profile properties (set once).
  Mixpanel.people.set_once({
    $created: date,
    "Email Captured By": "Web App",
    "Email Captured On": date,
    "Account Created On": date,
  });

  //  Event.
  Mixpanel.track("Web App Registration", {
    type: type,
  });

  CleverTap.trackEvent("Web App Registration", {
    type: type,
  });
};

const userRegistrationCompleted = async (
  firstName: string,
  lastName: string
) => {
  try {
    FB.trackRegistrationCompleted();
  } catch (error) {
    console.log("Facebook tracking error.");
  }

  try {
    GA.trackRegistrationCompleted();
  } catch (error) {
    console.log("Google tracking error.");
  }

  Mixpanel.people.set({
    $first_name: firstName,
    $last_name: lastName,
    $name: firstName + " " + lastName,
  });

  CleverTap.profilePush({
    $first_name: firstName,
    $last_name: lastName,
    $name: firstName + " " + lastName,
  });

  Mixpanel.people.set_once({
    "Account Completed On": getCurrentDate(),
  });

  //  Track mixpanel event.
  Mixpanel.track("Web App Account Registration Completed");

  CleverTap.trackEvent("Web App Account Registration Completed");
};

const passwordResetRequested = async () => {
  Mixpanel.track("Password Reset Requested");
  CleverTap.trackEvent("Password Reset Requested");
};

const passwordReset = async () => {
  Mixpanel.track("Password Reset");
  CleverTap.trackEvent("Password Reset");
};

const emailConfirmed = async () => {
  Mixpanel.track("Email Confirmed");
  CleverTap.trackEvent("Email Confirmed");
};

const smsRequested = async () => {
  try {
    FB.trackSMSRequested();
  } catch (error) {
    console.log("Facebook tracking error.");
  }

  try {
    GA.trackSMSRequested();
  } catch (error) {
    console.log("Google tracking error.");
  }

  Mixpanel.track("SMS Requested");
  CleverTap.trackEvent("SMS Requested");
};

const downloadLinkOpened = async (store: AppStore) => {
  if (store === AppStore.Apple) {
    try {
      FB.trackAppleLinkClicked();
    } catch (error) {
      console.log("Facebook tracking error.");
    }

    try {
      GA.trackAppleLinkClicked();
    } catch (error) {
      console.log("Google tracking error.");
    }
  } else {
    try {
      FB.trackPlayStoreLinkClicked();
    } catch (error) {
      console.log("Facebook tracking error.");
    }

    try {
      GA.trackPlayStoreLinkClicked();
    } catch (error) {
      console.log("Google tracking error.");
    }
  }

  Mixpanel.track("Download Link Opened", {
    store: store,
  });
  CleverTap.trackEvent("Download Link Opened", {
    store: store,
  });
};

const settingsUpdated = async (settings: AccountDetails) => {
  Mixpanel.people.set({
    $email: settings.email,
    $first_name: settings.firstName,
    $last_name: settings.lastName,
    $name: settings.firstName + " " + settings.lastName,
    $city: settings.city,
  });

  CleverTap.profilePush({
    Email: settings.email,
    Name: settings.firstName + " " + settings.lastName,
    City: settings.city,
  });

  Mixpanel.track("Profile Info Updated");
  CleverTap.trackEvent("Profile Info Updated");
};

const languageUpdated = async (language: string) => {
  Mixpanel.people.set({
    language: language,
  });
  CleverTap.profilePush({
    language: language,
  });

  Mixpanel.track("Language Changed");
  CleverTap.trackEvent("Language Changed");
};

const TrackingAPI = {
  initializeTracking,
  Account: {
    userLoggedIn,
    userRegistered,
    userRegistrationCompleted,
    passwordReset,
    passwordResetRequested,
    emailConfirmed,
    smsRequested,
    downloadLinkOpened,
  },
  InApp: {
    settingsUpdated,
    languageUpdated,
  },
};

export default TrackingAPI;
