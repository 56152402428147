import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import StripeContext from "./StripeContext";
import { Price } from "./types";
import * as API from "services/api/kairosApi";

export interface useStripeSelectors {
  prices: Price[];
}

export interface useStripeActions {
  getPrices: () => void;
}

const useStripe = (): HookResult<useStripeSelectors, useStripeActions> => {
  const { prices, setPrices } = useContext(StripeContext);

  const getPrices = async () => {
    API.Account.Stripe.getPrices().then((prices) => {
      setPrices(prices);
    });
  };

  return {
    selectors: {
      prices,
    },
    actions: {
      getPrices,
    },
  };
};

export default useStripe;
