import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import { useAuth } from "@neurosolutionsgroup/authentication";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as API from "services/api/kairosApi";

const Billing = () => {
  const { t } = useTranslation(["settings"]);

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const {
    actions: { getAuthentication },
  } = useAuth();

  const openPortal = () => {
    API.Account.Stripe.createPortalSession(
      getAuthentication().sessionTicket
    ).then((response) => {
      window.open(response.redirectUrl);
    });
  };

  return (
    <div className="mt-3">
      <h2>{t("billing.title")}</h2>
      <PlainCard className="p-3">
        {userProfile.isSubscribed ? (
          userProfile.isStripe ? (
            <div>
              <p>{t("billing.text.stripe.text")}</p>
              <BevelButton
                className="btn btn-primary mt-2 float-right"
                onClick={openPortal}
              >
                {t("billing.text.stripe.button")}
              </BevelButton>
            </div>
          ) : (
            <div>
              <p>{t("billing.text.app")}</p>
            </div>
          )
        ) : (
          <div>
            <span>{t("billing.text.nosubscription.text")}</span>
            <Link to="/app/billing">
              {t("billing.text.nosubscription.link")}
            </Link>
          </div>
        )}
      </PlainCard>
    </div>
  );
};

export default Billing;
