import { Price } from "hooks/billing/types";
import { LocalizationInfo } from "services/Location";
import { stripePriceToAppPrice } from "./mapping/Stripe";

export interface StripePrice {
  id: string;
  currency: string;
  productId: string;
  recurring: {
    interval: string;
    intervalCount: number;
    trialPeriodDays: number;
  };
  type: string;
  unitAmount: number;
  unitAmountDecimal: number;
}

export interface StripeCheckoutSessionResponse {
  sessionId: string;
}

const getPrices = async (): Promise<Price[]> => {
  const ENDPOINT =
    process.env.REACT_APP_KAIROS_API_ENDPOINT + "/Billing/Stripe/Prices";

  return fetch(ENDPOINT)
    .then((response) => {
      return response.json().then((response) => {
        return (response as StripePrice[]).flatMap((sp) => {
          const price = stripePriceToAppPrice(sp);

          return price ? price : [];
        });
      });
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const createCheckoutSession = async (
  priceId: string,
  sessionTicket: string,
  localization: LocalizationInfo,
  promoCodeId?: string
): Promise<StripeCheckoutSessionResponse> => {
  const ENDPOINT =
    process.env.REACT_APP_KAIROS_API_ENDPOINT +
    "/Billing/Stripe/CheckoutSession";

  const headers = {
    Authorization: sessionTicket,
    "Content-Type": "application/json",
  };

  return fetch(ENDPOINT, {
    method: "POST",
    headers,
    body: JSON.stringify({
      priceId,
      localization,
      promoCodeId,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

const createPortalSession = async (sessionTicket: string) => {
  const ENDPOINT =
    process.env.REACT_APP_KAIROS_API_ENDPOINT + "/Billing/Stripe/PortalSession";

  const headers = {
    Authorization: sessionTicket,
  };

  return fetch(ENDPOINT, {
    method: "POST",
    headers,
  }).then((response) => {
    return response.json();
  });
};

export { getPrices, createCheckoutSession, createPortalSession };
