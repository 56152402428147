import React, { ReactNode, useState } from "react";
import { Price } from "./types";

export interface StripeContextData {
  prices: Price[];
  setPrices: (prices: Price[]) => void;
}

export const StripeContextDefaultData: StripeContextData = {
  prices: [],
  setPrices: () => {},
};

const StripeContext = React.createContext<StripeContextData>(
  StripeContextDefaultData
);

export type Props = {
  children: ReactNode;
};

export const StripeProvider = (props: Props) => {
  const [prices, setPrices] = useState<Price[]>([]);

  return (
    <StripeContext.Provider value={{ prices, setPrices }}>
      {props.children}
    </StripeContext.Provider>
  );
};

export default StripeContext;
