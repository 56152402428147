import React, { FunctionComponent, HTMLAttributes } from "react";
import LogoHeader from "../../graphics/LogoHeader";

const LogoCard: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  className,
  ...props
}) => {
  return (
    <div className="card-wrap col-xl-5 col-lg-6 col-md-7 col-sm-10 logo-card">
      <div className={"card card-logo p-3 pt-5 " + className} {...props}>
        <LogoHeader />
        {props.children}
      </div>
    </div>
  );
};

export default LogoCard;
