//  Functions to manage information about the user.

const PROFILE_FIRSTNAME = "profile.firstname";
const PROFILE_LASTNAME = "profile.lastname";
const PROFILE_EMAIL = "profile.email";
const PROFILE_ISEMAILCONFIRMED = "profile.isEmailConfirmed";
const PROFILE_CITY = "profile.city";
const PROFILE_TYPE = "profile.type";
const PROFILE_ISSUBSCRIBED = "profile.isSubscribed";
const PROFILE_ISFREETRIAL = "profile.isFreeTrial";
const PROFILE_ISSTRIPE = "profile.isStripe";

export type UserProfile = {
  firstName: string;
  lastName: string;
  email: string;
  isEmailConfirmed: boolean;
  isContactEmailSet?: boolean;
  city: string;
  type: string;
  isSubscribed: boolean;
  isFreeTrial: boolean;
  isStripe: boolean;
};

var UserProfileService = (() => {
  var getFirstName = () => {
    return localStorage.getItem(PROFILE_FIRSTNAME);
  };

  var setFirstName = (firstName: string) => {
    localStorage.setItem(PROFILE_FIRSTNAME, firstName);
  };

  var getLastName = () => {
    return localStorage.getItem(PROFILE_LASTNAME);
  };

  var setLastName = (lastName: string) => {
    localStorage.setItem(PROFILE_LASTNAME, lastName);
  };

  var getEmail = () => {
    return localStorage.getItem(PROFILE_EMAIL);
  };

  var setEmail = (email: string) => {
    localStorage.setItem(PROFILE_EMAIL, email);
  };

  var getIsEmailConfirmed = () => {
    localStorage.getItem(PROFILE_ISEMAILCONFIRMED);
  };

  var setIsEmailConfirmed = (isEmailConfirmed: boolean) => {
    localStorage.setItem(PROFILE_ISEMAILCONFIRMED, isEmailConfirmed.toString());
  };

  var getCity = () => {
    localStorage.getItem(PROFILE_CITY);
  };

  var setCity = (city: string) => {
    localStorage.setItem(PROFILE_CITY, city);
  };

  var getType = () => {
    return localStorage.getItem(PROFILE_TYPE);
  };

  var setType = (type: string) => {
    localStorage.setItem(PROFILE_TYPE, type);
  };

  var getIsSubscribed = () => {
    return localStorage.getItem(PROFILE_ISSUBSCRIBED);
  };

  var setIsSubscribed = (isSubsscribed: boolean) => {
    localStorage.setItem(PROFILE_ISSUBSCRIBED, isSubsscribed.toString());
  };

  var getIsFreeTrial = () => {
    return localStorage.getItem(PROFILE_ISFREETRIAL);
  };

  var setIsFreeTrial = (isFreeTrial: boolean) => {
    localStorage.setItem(PROFILE_ISFREETRIAL, isFreeTrial.toString());
  };

  var getIsStripe = () => {
    return localStorage.getItem(PROFILE_ISSTRIPE);
  };

  var setIsStripe = (isStripe: boolean) => {
    localStorage.setItem(PROFILE_ISSTRIPE, isStripe.toString());
  };

  var setUserProfile = (userInternalData: UserProfile) => {
    setFirstName(userInternalData.firstName);
    setLastName(userInternalData.lastName);
    setEmail(userInternalData.email);
    setIsEmailConfirmed(userInternalData.isEmailConfirmed);
    setCity(userInternalData.city);
    setIsSubscribed(userInternalData.isSubscribed);
    setIsFreeTrial(userInternalData.isFreeTrial ?? "false");
    setIsStripe(userInternalData.isStripe);
  };

  var getUserProfile = (): UserProfile => {
    return {
      firstName: localStorageToString(PROFILE_FIRSTNAME),
      lastName: localStorageToString(PROFILE_LASTNAME),
      email: localStorageToString(PROFILE_EMAIL),
      isEmailConfirmed: localStorageToBoolean(PROFILE_ISEMAILCONFIRMED),
      city: localStorageToString(PROFILE_CITY),
      type: localStorageToString(PROFILE_TYPE),
      isSubscribed: localStorageToBoolean(PROFILE_ISSUBSCRIBED),
      isFreeTrial: localStorageToBoolean(PROFILE_ISFREETRIAL),
      isStripe: localStorageToBoolean(PROFILE_ISSTRIPE),
    };
  };

  var localStorageToString = (key: string): string => {
    return localStorage.getItem(key) ?? "";
  };

  var localStorageToBoolean = (key: string): boolean => {
    return localStorage.getItem(key) === "true" ?? false;
  };

  return {
    getFirstName,
    setFirstName,
    getLastName,
    setLastName,
    getEmail,
    setEmail,
    getIsEmailConfirmed,
    setIsEmailConfirmed,
    getCity,
    setCity,
    getType,
    setType,
    getIsSubscribed,
    setIsSubscribed,
    getIsFreeTrial,
    setIsFreeTrial,
    getIsStripe,
    setIsStripe,
    getUserProfile,
    setUserProfile,
  };
})();

export default UserProfileService;
