import axios from "axios";

async function postPromoCode(code: string, sessionTicket: string) {
  const ENDPOINT = process.env.REACT_APP_KAIROS_API_ENDPOINT + "/Billing/Promo";

  var headers = {
    Authorization: sessionTicket,
  };

  var data = {
    promoCode: code,
  };

  return axios
    .post(ENDPOINT, data, {
      headers: headers,
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error.response.data);
    });
}

export { postPromoCode };
