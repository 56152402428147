import React from "react";
import LogoCard from "components/layout/cards/LogoCard";
import CardPage from "components/layout/pages/CardPage";
import { SuspenseLoader } from "components/graphics";
import { Link, useHistory } from "react-router-dom";
import TrackingAPI from "services/tracking";
import { AccountType } from "services/tracking/TrackingAPI";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import {
  AuthError,
  RegistrationPage,
} from "@neurosolutionsgroup/authentication";
import {} from "@neurosolutionsgroup/authentication/dist/";

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation(["account", "forms"]);

  const onSuccess = (response: {
    playFabId: string;
    email: string;
    language: string;
  }) => {
    TrackingAPI.Account.userRegistered(
      response.playFabId,
      AccountType.Email,
      response.language,
      response.email
    ).catch(() => {
      // Errors are non-blocking.
      console.log("[Registration] Information update or tracking failed.");
    });

    history.push("/account/completeregistration");
  };
  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms:errors.title"),
        t("forms:errors.general") + ": " + t("forms:errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <RegistrationPage onSuccess={onSuccess} onFailure={onFailure} />

        <hr />

        <p className="text-center">
          {t("registration.menu.forgottendescription")}{" "}
          <Link to="/account/forgottenpassword">
            {t("registration.menu.forgottenlink")}
          </Link>
        </p>
      </LogoCard>
    </CardPage>
  );
};

export default function Registration() {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
