import { BevelButton } from "components/inputs";
import { Price } from "hooks/billing/types";
import React from "react";
import { useTranslation } from "react-i18next";
import GreenCheckMark from "assets/GeneralIcons/green_checkmark.png";

interface ProductCardProps {
  interval: "month" | "year";
  prices: Price[];
  currency: string;
  title: string;
  onClick: (priceId: string) => void;
}

const ProductCard = (props: ProductCardProps) => {
  const { t, i18n } = useTranslation(["settings"]);

  const price = props.prices.find(
    (p) => p.interval === props.interval && p.currency === props.currency
  );

  const calculateSavingPercentage = (): string => {
    const monthly =
      props.prices.find(
        (p) => p.interval === "month" && p.currency === props.currency
      )?.unitAmount ?? 0;

    const yearly =
      props.prices.find(
        (p) => p.interval === "year" && p.currency === props.currency
      )?.unitAmount ?? 0;

    return (((monthly * 12 - yearly) / (monthly * 12)) * 100).toFixed(0);
  };

  return (
    <div className={"price-card price-card-" + props.interval}>
      <div className={"price-card-inner price-card-inner-" + props.interval}>
        <h2>{props.title}</h2>
        <div className="price">
          {t(
            "settings:billing.amount." +
              (props.interval === "year" ? "yearly" : "monthly"),
            {
              amount: (
                (price?.unitAmount ?? 0) /
                100 /
                (props.interval === "year" ? 12 : 1)
              ).toLocaleString(i18n.language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }),
            }
          )}
        </div>
        <div className="text-center mb-2">
          {props.interval === "year"
            ? t("settings:billing.billing.yearly", {
                amount: ((price?.unitAmount ?? 0) / 100).toLocaleString(
                  i18n.language
                ),
              })
            : t("settings:billing.billing.monthly")}
        </div>
        <div className="my-3 d-flex flex-column">
          <p className="mb-3 marketing-title">
            {t("billing.marketing.title")} :
          </p>

          <div className="mx-auto">
            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.one")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.two")}</p>
            </div>

            <div className="d-flex">
              <div className="price-card-footer-checkmark-img">
                <img src={GreenCheckMark} alt="Checkmark" />
              </div>
              <p>{t("billing.marketing.point.three")}</p>
            </div>
          </div>
        </div>
        <BevelButton
          className="btn btn-primary"
          onClick={() => {
            if (price) {
              props.onClick(price.id);
            }
          }}
        >
          {t("billing.buy")}
        </BevelButton>
      </div>
      <div className="deal-tag">
        <p>{t("billing.save")}</p>
        <p className="value">{calculateSavingPercentage()}%</p>
      </div>
    </div>
  );
};

export default ProductCard;
